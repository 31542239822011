import { PageConfig } from '../../routing'
import Loading from '../Loading'
import Redirect from '../Redirect'
import Settings from '../Settings/pages/View'

import ROUTE, { PathArgs } from './route'

const Home: PageConfig<unknown, PathArgs> = () => (
  <>
    <Loading />
    <Redirect to={Settings.route.path()} />
  </>
)

Home.route = ROUTE

export default Home
